(function(ng) {
	function isModuleAvailable(name) {
		try {
			return angular.module(name).name === name;
		} catch (e) {
			return false;
		}
	}

	angular
		.module('fca.brandApp')
		.controller('brandAppMainController', MainController);

	/**
	 * @ngdoc controller
	 * @name fca.brandApp.controller:brandAppMainController
	 */
	function MainController(
		$http,
		$rootScope,
		$scope,
		gtmAnalytics,
		fcaGeolocator,
		cookieLocation,
		userLocation,
		storageService,
		focusFunctions,
		$timeout,
		$compile) {
		'ngInject';

		// Default arguments to invoke geolocation method
		let args = [];

		/**
		 * @ngdoc property
		 * @name location
		 * @description User location object
		 * @type {Object}
		 */
		this.location = null;

		// Listen to this event to compile angular element (event send from vuejs)
		document.addEventListener('fca-compile-element', (e) => {
			$compile(e.detail.el)($rootScope);
		});

		this.initialize = () => {

			focusFunctions.init();

			this.isCookiesEnabled = storageService.isCookiesEnabled();

			/* eslint-disable prefer-spread */
			if (cookieLocation.isCookieExists()) {
				this.location = cookieLocation.getLocation();
			}

			// if we don't have a location, use the default one until we geo-locate the user
			if (this.location == null) {
				this.location = fcaGeolocator.getDefaultLocation();
			}
			// add data-attribute on the body to get access to the location name
			$('body').attr('data-location-province', this.location.province);

			let dealerId;
			this.hashParameters = getHashParameters();
			if (this.hashParameters.dealerId && this.hashParameters.dealerId !== 'null') {
				window.FcaCookieChecker.addSessionStorage('dealerId', this.hashParameters.dealerId);
				dealerId = this.hashParameters.dealerId;
			} else {
				dealerId = storageService.sessionStorageGetItem('dealerId');
			}

			// check if we have a dealer website vendor
			if (this.hashParameters.vendor && this.hashParameters.vendor !== 'null') {
				window.FcaCookieChecker.addSessionStorage('vendor', this.hashParameters.vendor);
			}

			// check if we have a dealer website url
			if (this.hashParameters.dealerUrl && this.hashParameters.dealerUrl !== 'null') {
				window.FcaCookieChecker.addSessionStorage('dealer-url', this.hashParameters.dealerUrl);
			}


			// TODO : https://issues.nurun.com/browse/FCATM-1564
			function getProvinceLabel(provinceCode) {
				return window.FCA_SITES_CONFIG.location[provinceCode]['desc'];
			}

			// Function to retrieve the last part of the URL path without query parameters
			function getLastPathSegment(url) {
				const urlObj = new URL(url);
				return urlObj.pathname.split('/').filter(Boolean).pop();
			}

			// Function to update the last part of the URL path
			function updateLastPathSegment(url, newSegment) {
				const urlObj = new URL(url);
				const segments = urlObj.pathname.split('/').filter(Boolean);
				if (segments.length > 0) {
					segments[segments.length - 1] = newSegment;
					urlObj.pathname = '/' + segments.join('/');
				}
				return urlObj.toString();
			}


			this.handleLocationChanged = (newLocation) => {
				const language = window.FCA_SITES_CONFIG.language;
				// If the url ends with a region name, then any location change will refresh the page automatically
				const lastPartUrl = getLastPathSegment(window.location.href);
				// get region from last part url
				const lastPartRegion = Object.values(window.FCA_SITES_CONFIG.location).find(province => language === 'en' ? province.region === lastPartUrl : province.regionFr === lastPartUrl);
				// Check if the region is different from the one in the url, if yes redirect
				if (lastPartRegion && lastPartRegion.region !== newLocation.region) {
					const newRegionUrl = Object.values(window.FCA_SITES_CONFIG.location).find(province => province.region === newLocation.region);
					const newLastPartUrl = language === 'en' ? newRegionUrl.region : newRegionUrl.regionFr;
					window.location.href = updateLastPathSegment(window.location.href, newLastPartUrl);
				}

				// Location
				this.location = newLocation;

				$('.js-vehiclerecommender').trigger({
					type: 'vehicle-recommender-event:location-change',
					lat: this.location.latitude,
					long: this.location.longitude,
					postalCode: this.location.postalCode,
				});

				// Set user location but without broadcasting event location change
				userLocation.setLocation(newLocation, true);

				// Set new location in cookie
				cookieLocation.setLocation(newLocation);
				fcaGeolocator.setProvinceCookie(newLocation.province);

				//  emit legacy locationUpdated
				let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
				$rootScope.$broadcast(eventUpdateName, [newLocation]);

				$('body').attr('data-location-province', this.location.province);

				// emit legacy locationChanged
				const eventChangeName = fcaGeolocator.getLocationChangedEvent();
				$rootScope.$broadcast(eventChangeName, [newLocation]);

				// emit legacy regionalOffers.locationChange
				$rootScope.$emit('regionalOffers.locationChange', newLocation, newLocation.province, newLocation.region, getProvinceLabel(newLocation.province));
			};

			// TODO : FCATM-1564
			window.PubSubManager.subscribe('location:locationChanged', (newLocation) => {
				$timeout(() => {
					this.handleLocationChanged(newLocation);
				});
			}, true);

			// This condition is for the iframe mode of the build and price,
			if (dealerId) {
				const dealerKey = 'dealer-lock';
				const dealerProvinceKey = 'dealerProvince';

				// retrieve the dealer from session storage
				let dealerFromStorage = JSON.parse(sessionStorage.getItem(dealerKey));

				/**
				 * once the dealer is geo-coded, change the user's location to the same as the dealer
				 */
				let dealerProvinceLoaded = function(dealerProvince) {
					userLocation.changeLocation(dealerProvince, true);
				};

				/**
				 * once the dealer object is retrieved, get the dealer's geo-location
				 */
				let dealerLoaded = function(dealer) {
					let dealerProvince = JSON.parse(sessionStorage.getItem(dealerProvinceKey));
					if (dealerProvince) {
						dealerProvinceLoaded(dealerProvince);
					} else {
						let province = dealer.province;
						fcaGeolocator.geocodeWithPostalCode(userLocation.getDefaultPostalCodeForProvince(province)).then((location) => { // eslint-disable-line max-len
							dealerProvince = {'region': `${location.province},${location.region}`};
							window.FcaCookieChecker.addSessionStorage(dealerProvinceKey, JSON.stringify(dealerProvince));
							dealerProvinceLoaded(dealerProvince);
						});
					}
				};

				if (dealerFromStorage) {
					dealerLoaded(dealerFromStorage);
				} else {
					// if we don't have the dealer from storage, retrieve it
					let brandCode = FCA_SITES_CONFIG.name;
					$http.get(`/data/dealers/` + brandCode + `/` + dealerId).then((result) => {
						if (result.data) {
							let dealerObject = result.data;
							// save the dealer in session storage
							window.FcaCookieChecker.addSessionStorage(dealerKey, JSON.stringify(dealerObject));
							dealerLoaded(dealerObject);
						} else {
							console.error("could not retrieve dealer " + dealerId);
						}
					});
				}
			}

			// If the list of nearest dealers has instances of chat availability, broadcast it to other components.
			$scope.$on('nearestDealersWithChatObtained', (evt, args) => {
				$('body').attr('data-page-has-chat-popup', 'true');
				$scope.$broadcast('nearestDealersWithChatUpdated');
			});

			// If the list of nearest dealers has no instances of chat availability, broadcast it to other components.
			$scope.$on('noNearestDealersWithChatObtained', (evt, args) => {
				$('body').attr('data-page-has-chat-popup', 'false');
				$scope.$broadcast('noNearestDealersWithChatUpdated');
			});

			// Upon request, close overlays and lightboxes and show the dealer chat popup
			$scope.$on('requestDealersChatPopup', (evt, args) => {
				$scope.$broadcast('openDealersChatPopup');
			});

			$rootScope.$on('$translateLoadingEnd', (evt, args) => {
				$('body').removeClass('translate-loading');
			});

			// expose ourself to the window.fca namespace (debug purpose mostly)
			window.fca = window.fca || {};
			window.fca.brandAppMainController = this;

			return this;
		};

		/**
		 * Safe scope apply method
		 */
		this.scopeApply = () => {
			$timeout(() => {
				$scope.$apply();
			});
		};

		gtmAnalytics.trackPage();
		this.initialize();
	}

	angular.element(function() {
		// this module is only loaded on the /en/compare page
		// if this module doesn't exist we create an empty module (not to break the bootstrap)
		if (!isModuleAvailable('fca.core.competitionCompare')) {
			angular.module('fca.core.competitionCompare', []);
		}

		angular.bootstrap(document, ['fca.brandApp']);
	});
})();
