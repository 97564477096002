(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator')
		.run(function() {
			'ngInject';
		})
		.controller('fcaGeolocatorStatusController', fcaGeolocatorStatusController)
		.component('fcaGeolocatorStatus', {
			controller: 'fcaGeolocatorStatusController',
			controllerAs: '$ctrl',
			templateUrl: '/geolocator/geolocator.status.html',
			transclude: true,
			bindings: {
				skin: '@',
				location: '<?',
				customLabel: '@?',
				viewAllUrl: '@?',
				brand: '@',
				displayType: '@',
				showProvince: '<',
				useTransclude: '<',
				isAlfaRomeo: '<',
				analyticsId: '@',
				/** Will add the css class '-branded' to the location button (if displayType == 'default') */
				isBranded:'<'
			}
		});

	function fcaGeolocatorStatusController($rootScope, userLocation, storageService, fcaGeolocator, cookieLocation) {
        'ngInject';
		/* eslint-disable no-invalid-this */
		/**
		 * @ngdoc property
		 * @name location
		 * @propertyOf fca.geolocator.controller:fcaGeolocatorStatusController
		 * @description [TODO]
		 * @type {Object}
		 */
		this.location = null;

		// Only display a city or postal code if the user manually chose to change it's location in the dealer-locator
		this.locationUpdated = false;

		this.useTransclude = false;

		this.isCookiesEnabled = storageService.isCookiesEnabled();

		/**
		 * @ngdoc property
		 * @name dialogModel
		 * @propertyOf fca.geolocator.controller:fcaGeolocatorStatusController
		 * @description Dialog model data to inject into modal
		 * @type {Object}
		 */
		this.dialogModel = {
			viewAllUrl: null,
			title: 'geolocation.set.location.title'
		};

		this.$onInit = () => {
			this.location = cookieLocation.getLocation();
			this.setLocationDefaultLabel(userLocation.getLocationLabel());

			let title = this.dialogModel.title;
			if (this.skin === 'sni') {
				title = 'sni.location.dialog.title';
			}

			console.debug("initialized geolocator status controller");
		};

		/**f
		 * @ngdoc method
		 * @name setLocation
		 * @propertyOf fca.geolocator.controller:fcaGeolocatorStatusController
		 * @description [TODO]
		 * @example
		 * <pre>[TODO]</pre>
		 */
		this.setLocation = (data) => {
			let location = data || {};
			if (!ng.equals(location, {})) {
				this.location = ng.extend({}, this.location, location);

				// TODO : FCATM-1564
				window.PubSubManager.publish('location:locationChanged', this.location);

				// Broadcast a JavaScript event for the vehicle recommender (potential refactor longterm)
				$('.js-vehiclerecommender').trigger({
					type: 'vehicle-recommender-event:location-change',
					lat: this.location.latitude,
					long: this.location.longitude
				});
			}
		};

		this.setLocationDefaultLabel = locationLabel => {
			this.defaultLocationLabel = ` (${locationLabel})`;
		};

		this.sendAnalyticsId = () => {
			let id = "";
			if (this.analyticsId) {
				id = this.analyticsId;
			}
			return id;
		};

		this.openPopup = (analyticsId) => {
			window.PubSubManager.publish("location:openModal", {analyticsId});
		};

		$rootScope.$on('regionalOffers.locationChange', (ev, pos, prov, reg, locationLabel) => {
			this.setLocationDefaultLabel(locationLabel);
		});

		/* Listen if the location is changed outside this component */
		let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
		$rootScope.$on(eventUpdateName, (evt, args) => {
			
			let [location] = args;
			if (!ng.equals(location, this.location)) {
				this.location = location;
			}

			this.setLocationDefaultLabel(window.FCA_SITES_CONFIG.location[location.province].desc);
		});
	}
})(angular);
